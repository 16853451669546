import { Box, Grid, Typography } from '@material-ui/core';

import { useCheckout } from '../../../contexts/Checkout/useCheckout';
import cartHelper from '../../../helpers/cartHelper';
import { BlackSmallCircularProgress } from '../BlackSmallCircularProgress';

interface Props {
    loadingTotalPrice: boolean;
}
const Total = ({ loadingTotalPrice }: Props) => {
    const { totalPriceToShow, getMPInterestForCart } = useCheckout();

    return (
        <>
            <Grid container spacing={3} style={{ marginTop: '8px' }}>
                {!loadingTotalPrice && getMPInterestForCart() > 0 && (
                    <>
                        <Grid item xs={4}>
                            <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                                Interés
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Box display="flex" justifyContent="flex-end">
                                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                                    {cartHelper.formatPrice(getMPInterestForCart())}
                                </Typography>
                            </Box>
                        </Grid>
                    </>
                )}
                <Grid item xs={4}>
                    <Typography variant="h6">Total</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box display="flex" justifyContent="flex-end">
                        {loadingTotalPrice || totalPriceToShow === undefined ? (
                            <BlackSmallCircularProgress />
                        ) : (
                            <Typography variant="h3">{cartHelper.formatPrice(totalPriceToShow)}</Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Total;
