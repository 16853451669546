import { ProductTypes, peripheralTypesDefinitions, productTypesDefinitions } from '../../../helpers/types';
import offersIcon from '../../../productImages/icons/offers.svg';
import { ProductTypeOrCategoryDefinition, ProductsCategoryDefinition } from '../../../types/ProductTypeFrontendDefinition';

interface ProductTypesFilterSection {
    title: string;
    items: ProductTypeOrCategoryDefinition[];
}

const componentProductTypesDefinitions = [ProductTypes.CASE, ProductTypes.CPU, ProductTypes.MOTHER, ProductTypes.POWER, ProductTypes.RAM, ProductTypes.STORAGE, ProductTypes.GPU].map(
    (type) => productTypesDefinitions[type]
);

const refrigerationCategory: ProductsCategoryDefinition = {
    title: 'Refrigeración',
    productTypes: [productTypesDefinitions[ProductTypes.CPU_COOLER], productTypesDefinitions[ProductTypes.CASE_FAN], productTypesDefinitions[ProductTypes.THERMAL_PASTE]],
};

const firstSection = [...componentProductTypesDefinitions, refrigerationCategory];
const secondSection = peripheralTypesDefinitions;
const thirdSection = [ProductTypes.BUILD, ProductTypes.NOTEBOOKS].map((type) => productTypesDefinitions[type]);

export const productTypeFilterSections: ProductTypesFilterSection[] = [
    { items: firstSection, title: 'Componentes de PC' },
    { items: secondSection, title: 'Periféricos' },
    { items: thirdSection, title: 'Pc armadas' },
];

export const offersSection = {
    title: 'Ofertas',
    icon: offersIcon,
    type: 'offers',
};
