import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useContext } from 'react';

import { ProductsFiltersContext } from '../../../contexts/ProductsFilters';
import { ProductType } from '../../../types/ProductType';
import { offersSection, productTypeFilterSections } from '../data/productTypeFilterSections';
import ProductTypeButton from './ProductTypeButton';
import { useProductCategoriesStyles } from './styles';

const ProductCategories = () => {
    const classes = useProductCategoriesStyles();
    const [, { setProductType, setOffersOnly }] = useContext(ProductsFiltersContext);

    const handleClick = (productType: ProductType) => {
        setProductType(productType);
    };

    const handleClickOffers = () => {
        setOffersOnly(true);
    };
    return (
        <Grid container direction="column" className={classes.container}>
            <Typography variant="h4" className={classes.title}>
                Productos
            </Typography>
            {productTypeFilterSections.map(({ title, items }) => (
                <Accordion key={title} defaultExpanded className={classes.accordion}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <Grid container spacing={2} item xs={12} justifyContent="flex-start">
                            {items.map((product) => {
                                if ('type' in product) {
                                    return (
                                        <Grid key={product.title} item xs={6} md={3}>
                                            <ProductTypeButton
                                                title={product.title}
                                                image={product.icon}
                                                onClick={() => {
                                                    handleClick(product.type);
                                                }}
                                            />
                                        </Grid>
                                    );
                                } else {
                                    return product.productTypes.map((productType) => (
                                        <Grid key={productType.title} item xs={6} md={3}>
                                            <ProductTypeButton
                                                title={productType.title}
                                                image={productType.icon}
                                                onClick={() => {
                                                    handleClick(productType.type);
                                                }}
                                            />
                                        </Grid>
                                    ));
                                }
                            })}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Accordion defaultExpanded className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Ofertas</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                    <Grid container spacing={2} item xs={12} justifyContent="flex-start">
                        <Grid item xs={12}>
                            <ProductTypeButton
                                title={offersSection.title}
                                image={offersSection.icon}
                                onClick={() => {
                                    handleClickOffers();
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default ProductCategories;
