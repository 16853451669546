import accessoriesIcon from '../productImages/icons/accessories.svg';
import cpuCaseIcon from '../productImages/icons/case.svg';
import chairsIcon from '../productImages/icons/chair.svg';
import coolerIcon from '../productImages/icons/cooler.svg';
import cpuIcon from '../productImages/icons/cpu.svg';
import desksIcon from '../productImages/icons/desk.svg';
import desktop from '../productImages/icons/desktop.svg';
import gpuIcon from '../productImages/icons/gpu.svg';
import headphonesIcon from '../productImages/icons/headphone.svg';
import keyboardIcon from '../productImages/icons/keyboard.svg';
import microphoneIcon from '../productImages/icons/microphone.svg';
import monitorIcon from '../productImages/icons/monitor.svg';
import motherIcon from '../productImages/icons/mother.svg';
import mouseIcon from '../productImages/icons/mouse.svg';
import notebookIcon from '../productImages/icons/notebook.svg';
import powerIcon from '../productImages/icons/power.svg';
import ramIcon from '../productImages/icons/ram.svg';
import speakersIcon from '../productImages/icons/speaker.svg';
import storageIcon from '../productImages/icons/storage.svg';
import thermalPasteIcon from '../productImages/icons/thermalPaste.svg';
import accessories from '../productImages/images/accessories.png';
import chairs from '../productImages/images/chair.png';
import desks from '../productImages/images/desk.png';
import headphones from '../productImages/images/headphone.png';
import keyboard from '../productImages/images/keyboard.png';
import microphone from '../productImages/images/microphone.png';
import monitor from '../productImages/images/monitor.png';
import mouse from '../productImages/images/mouse.png';
import speakers from '../productImages/images/speaker.png';
import storage from '../productImages/images/storage.png';
import { ProductType } from '../types/ProductType';
import { ProductTypeDefinition } from '../types/ProductTypeFrontendDefinition';

export const CartProductTypes = {
    BUILD: 1,
    PRODUCT: 2,
    PRO_BUILDERIcon: 3,
};

export const ProductTypes = {
    MOTHER: 'mother',
    CPU: 'cpu',
    CPU_COOLER: 'cpu_cooler',
    RAM: 'ram',
    GPU: 'gpu',
    STORAGE: 'storage',
    CASE: 'case',
    CASE_FAN: 'case_fan',
    POWER: 'power',
    MONITOR: 'monitor',
    MOUSE: 'mouse',
    KEYBOARD: 'keyboard',
    HEADSET: 'headset',
    MICROPHONE: 'microphone',
    CHAIR: 'chair',
    ACCESSORIES: 'accessories',
    THERMAL_PASTE: 'thermal_paste',
    SPEAKERS: 'speakers',
    FURNITURE: 'furniture',
    OPERATIVE_SYSTEM: 'operative_system',
    OTHER: 'other',
    BUILD: 'build',
    NOTEBOOKS: 'notebook',
} as const;

export const CpuBrands = {
    INTEL: 'intel',
    AMD: 'amd',
};

export const RamStickTypes = {
    DDR_3: 'ddr3',
    DDR_4: 'ddr4',
    DDR_5: 'ddr5',
};

export const CpuSockets = {
    AM4: 'AM4',
    AM5: 'AM5',
    TRX4: 'TRX4',
    LGA1151: '1151',
    LGA1200: '1200',
    LGA1700: '1700',
    LGA1851: '1851',
    LGA2011: '2011',
};

export const CaseFanSlotWidths = {
    W_120: 120,
    W_140: 140,
    W_240: 240,
    W_280: 280,
    W_360: 360,
};

export const CoolerTypes = {
    AIR_COOLER: 'AirCooler',
    WATER_COOLER: 'WaterCooler',
};

export const StorageConnections = {
    M2: 'M2',
    SATA: 'SATA',
    USB: 'USB',
};

export const StorageTypes = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
};

export const StorageTypeLabels = {
    [StorageTypes.INTERNAL]: 'Discos internos',
    [StorageTypes.EXTERNAL]: 'Discos externos',
};

export const StorageTechnologies = {
    SSD: 'SSD',
    HDD: 'HDD',
};

export const FormFactors = {
    FULL_TOWER: 'Full-Tower',
    MID_TOWER: 'Mid-Tower',
    MINI_TOWER: 'Mini-Tower',
};

export const productTypesDefinitions: Record<ProductType, ProductTypeDefinition> = {
    [ProductTypes.CASE]: {
        title: 'Gabinetes',
        image: cpuCaseIcon,
        icon: cpuCaseIcon,
        type: ProductTypes.CASE,
    },
    [ProductTypes.CPU]: {
        title: 'Microprocesadores',
        image: cpuIcon,
        icon: cpuIcon,
        type: ProductTypes.CPU,
    },
    [ProductTypes.GPU]: {
        title: 'Placas de video',
        image: gpuIcon,
        icon: gpuIcon,
        type: ProductTypes.GPU,
    },
    [ProductTypes.MOTHER]: {
        title: 'Motherboard',
        image: motherIcon,
        icon: motherIcon,
        type: ProductTypes.MOTHER,
    },
    [ProductTypes.POWER]: {
        title: 'Fuentes',
        image: powerIcon,
        icon: powerIcon,
        type: ProductTypes.POWER,
    },
    [ProductTypes.RAM]: {
        title: 'Memorias',
        image: ramIcon,
        icon: ramIcon,
        type: ProductTypes.RAM,
    },
    [ProductTypes.CPU_COOLER]: {
        title: 'CPU Cooler',
        image: coolerIcon,
        icon: coolerIcon,
        type: ProductTypes.CPU_COOLER,
    },
    [ProductTypes.CASE_FAN]: {
        title: 'Fans',
        image: coolerIcon,
        icon: coolerIcon,
        type: ProductTypes.CASE_FAN,
    },
    [ProductTypes.THERMAL_PASTE]: {
        title: 'Pasta térmica',
        image: thermalPasteIcon,
        icon: thermalPasteIcon,
        type: ProductTypes.THERMAL_PASTE,
    },
    [ProductTypes.ACCESSORIES]: {
        title: 'Accesorios',
        image: accessories,
        icon: accessoriesIcon,
        type: ProductTypes.ACCESSORIES,
        isPeripheral: true,
    },
    [ProductTypes.HEADSET]: {
        title: 'Auriculares',
        image: headphones,
        icon: headphonesIcon,
        type: ProductTypes.HEADSET,
        isPeripheral: true,
    },
    [ProductTypes.STORAGE]: {
        title: 'Almacenamiento',
        image: storage,
        icon: storageIcon,
        type: ProductTypes.STORAGE,
        isPeripheral: true,
        peripheralTitle: 'Externos',
        peripheralInitialSpecsFilter: { type: new Set([StorageTypes.EXTERNAL]) },
    },
    [ProductTypes.MICROPHONE]: {
        title: 'Micrófonos',
        image: microphone,
        icon: microphoneIcon,
        type: ProductTypes.MICROPHONE,
        isPeripheral: true,
    },
    [ProductTypes.FURNITURE]: {
        title: 'Mobiliario',
        image: desks,
        icon: desksIcon,
        type: ProductTypes.FURNITURE,
        isPeripheral: false /* por lo pronto se oculta de perifericos */,
    },
    [ProductTypes.MONITOR]: {
        title: 'Monitores',
        image: monitor,
        icon: monitorIcon,
        type: ProductTypes.MONITOR,
        isPeripheral: true,
    },
    [ProductTypes.MOUSE]: {
        title: 'Mouse',
        image: mouse,
        icon: mouseIcon,
        type: ProductTypes.MOUSE,
        isPeripheral: true,
    },
    [ProductTypes.SPEAKERS]: {
        title: 'Parlantes',
        image: speakers,
        icon: speakersIcon,
        type: ProductTypes.SPEAKERS,
        isPeripheral: true,
    },
    [ProductTypes.CHAIR]: {
        title: 'Sillas',
        image: chairs,
        icon: chairsIcon,
        type: ProductTypes.CHAIR,
        isPeripheral: true,
    },
    [ProductTypes.KEYBOARD]: {
        title: 'Teclados',
        image: keyboard,
        icon: keyboardIcon,
        type: ProductTypes.KEYBOARD,
        isPeripheral: true,
    },
    [ProductTypes.BUILD]: {
        icon: desktop,
        image: desktop,
        title: 'Pc Gamer',
        type: ProductTypes.BUILD,
    },
    [ProductTypes.OPERATIVE_SYSTEM]: {
        title: 'Sistemas operativos',
        image: cpuCaseIcon, //No se usa hoy xq no hay publicados productos de este tipo, pongo cualquier imagen e icono
        icon: cpuCaseIcon,
        type: ProductTypes.OPERATIVE_SYSTEM,
    },
    [ProductTypes.OTHER]: {
        title: 'Otros',
        image: cpuCaseIcon, //No se usa hoy xq no hay publicados productos de este tipo, pongo cualquier imagen e icono
        icon: cpuCaseIcon,
        type: ProductTypes.OTHER,
    },
    [ProductTypes.NOTEBOOKS]: {
        title: 'Notebooks',
        icon: notebookIcon,
        image: notebookIcon,
        type: ProductTypes.NOTEBOOKS,
    },
};

const peripheralTypes: ProductType[] = Object.entries(productTypesDefinitions)
    .filter(([, { isPeripheral }]) => isPeripheral)
    .map(([productType]) => productType as ProductType);
export const peripheralTypesDefinitions = peripheralTypes.map((type) => productTypesDefinitions[type]);
